.headerEl {
  padding: 0px 0px;
  @media screen and (max-width: 767px) {
    padding: 20px 0px;
  }
}

.logo {
  float: left;
  @media screen and (max-width: 767px) {
    width: 110px;
  }
}
.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.navbarCustom {
  float: right;
  @media screen and (max-width: 767px) {
    float: left;
    width: 100%;
    padding-top: 20px;
    display: none;
  }
}

.navbarHandler {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    float: right;
    padding-top: 10px;
    img {
      width: 30px;
    }
  }
}
