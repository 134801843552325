.footerEl {
  padding: 50px 0px 60px 0px;
  background: #f8f8f8;

  @media screen and (max-width: 767px) {
    padding: 50px 0px 20px 0px;
  }
}

.copyrightText {
  float: left;
  width: 100%;

  span {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: #868f9a;
    font-weight: 400;
  }
}

.footerList {
  float: left;
  width: 100%;

  ul {
    padding: 0px;
    list-style: none;
    margin: 0px;

    li {
      margin-bottom: 7px;
      a {
        color: #767676;
        font-weight: 500;
        letter-spacing: 0.2px;
        svg {
          width: 22px !important;
          margin-right: 5px;
        }
        :hover {
          color: #216ed3;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.footerHeading1 {
  color: #555555;
  font-size: 20px;
  margin: 0px 0px 30px 0px;
  letter-spacing: 0.2px;
}

.copyrightsSec {
  background: #f8f8f8;
  .container {
    border-top: 1px solid silver;
    padding: 30px 0px;

    @media screen and (max-width: 992px) {
      border-top: 1px solid silver;
      padding: 20px 15px;
    }

    @media screen and (max-width: 767px) {
      .copyrightText {
        float: left;
        width: 100%;

        margin-bottom: 12px;

        span {
          font-size: 15px;
          letter-spacing: 0.3px;
          color: #868f9a;
          font-weight: 400;
          line-height: 20px;
          display: block;
        }
      }
    }
  }
}

.equalCol {
  align-items: center;
}

.socialIcons1 {
  text-align: right;

  @media screen and (max-width: 767px) {
    text-align: left;
    a {
      margin: 0px 12px 0px 0px;
    }
  }

  a {
    color: #868f9a;
    font-size: 18px;
    margin-left: 10px;

    :hover {
      color: #216ed3;
    }
  }
}
