.topBar {
  background: #0077db;
  padding: 11px 0px;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.topbarLeft {
  float: left;
  width: 100%;

  a {
    color: white !important;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.2px;
    margin-right: 10px;
  }
}

.topbarRight {
  float: left;
  width: 100%;

  a,
  span {
    color: white;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.2px;
    margin-left: 15px;
  }
}
